<template>
  <div class="pageContol studentList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">测评管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">测评结果统计</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">结果汇总</a>
        </span>
      </div>
      <div class="framePage-body">
        <!-- <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox searMg">
            <div title="测评问卷名称" class="searchboxItem ci-full">
              <span class="itemLabel">测评问卷名称:</span>
              <el-input v-model="retrievalData.paperName" type="text" size="small" placeholder="请输入测评问卷名称" clearable />
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
          </div>
        </div> -->
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" fixed />
              <el-table-column label="分类名称" align="left" show-overflow-tooltip prop="componentName" minWidth="150" />
              <el-table-column label="分段" align="left" prop="endDate" show-overflow-tooltip min-width="240">
                <template slot-scope="scope">
                  {{ scope.row.minScore }} - {{ scope.row.maxScore }}
                </template>
              </el-table-column>
              <el-table-column label="答题人数" align="left" show-overflow-tooltip prop="peopleNum" minWidth="100" />
              <!-- <el-table-column label="处理字典" align="left" show-overflow-tooltip prop="evaluateCategory" minWidth="100">
                <template slot-scope="scope">
                  {{ $setDictionary("HR_EVALUATE_CATEGORY", scope.row.evaluateCategory) }}
                </template>
              </el-table-column> -->
              <!-- <el-table-column label="处理字段" align="left" prop="endDate" show-overflow-tooltip min-width="240">
                <template slot-scope="scope">
                  {{ scope.row.startTime | moment }} - {{ scope.row.endTime | moment }}
                </template>
              </el-table-column> -->
              <el-table-column label="操作" align="center" width="180px">
                <template slot-scope="scope">
                  <el-button type="text" style="padding: 0px 5px" size="mini"
                    @click="checkList(scope.row)">查看名单</el-button>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <!-- <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" /> -->
      </div>
    </div>
    <!-- 下载二维码 -->
    <el-dialog title="查看名单" :visible.sync="checkListData.dialogVisible" @closed="checkListDataClosed" width="600px">
      <div class="checkListData_1">{{ checkListData.componentName }}</div>
      <div class="checkListData_2">
        <span class="checkListData_2_1">{{ checkListData.minScore }}</span>~
        <span class="checkListData_2_2">{{ checkListData.maxScore }}</span>
        <span>分</span>
      </div>
      <el-table ref="multipleTable" :data="checkListData.checkListDatas" :height="500" size="small" tooltip-effect="dark"
        :header-cell-style="tableHeader" stripe>
        <el-table-column label="序号" align="center" type="index" :index="indexMethod" fixed />
        <el-table-column label="姓名" align="left" show-overflow-tooltip prop="name" minWidth="100" />
        <el-table-column label="手机号" align="left" show-overflow-tooltip prop="mobile" minWidth="150" />
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "evaluationManagement_evaluationResultsSummary",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 页面数据
      pageData: {
        paperId: "", // 测评问卷id
      },
      // 检索数据
      retrievalData: {
        paperName: "", // 优惠券名称
      },
      // 查看名单 - 弹框数据
      checkListData: {
        dialogVisible: false, // 弹框状态
        componentName: "", // 分类名称
        minScore: "", // 分段 - 最小值
        maxScore: "", // 分段 - 最大值
        checkListDatas: [], // 名单列表
      }
    };
  },
  created() {
    this.pageData.paperId = this.$route.query.paperId;
  },
  watch: {},
  computed: {},
  mounted() { },
  methods: {
    // 获取 - 列表数据
    getData(pageNum = 1) {
      const params = {
        paperId: this.$route.query.paperId
      };
      if (this.retrievalData.paperName) {
        params.paperName = this.retrievalData.paperName;
      }
      this.doFetch({
        url: "/biz/hr/evaluate/paper/resultSummaryPageList",
        params,
        pageNum,
      }, true, 6);
    },
    // 查看名单
    checkList(row) {
      this.$post("/biz/hr/evaluate/paper/resultSummaryPeopleList", {
        paperId: this.pageData.paperId,
        componentId: row.componentId,
        minScore: row.minScore,
        maxScore: row.maxScore
      }, 3000, true, 6).then(res => {
        this.checkListData.componentName = row.componentName;
        this.checkListData.minScore = row.minScore;
        this.checkListData.maxScore = row.maxScore;
        this.checkListData.checkListDatas = res?.data ?? [];
        this.checkListData.dialogVisible = true;
      });
    },
    // 查看名单 - 弹框 - 关闭 
    checkListDataClosed() {
      this.checkListData = this.$options.data().checkListData;
    },
    // 表格高度处理
    getTableHeight(opDom = true, page = true, tr = 40) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 4) * 16;
      if (opDom) {
        tHeight -= + 0.675 * 16 + 1;
      }
      // if (page) {
      //   tHeight -= 32;
      // }
      this.tableHeight = tHeight;
    },
  },
};
</script>
<style lang="less" scoped>
.checkListData_1 {
  font-size: 16px;
  font-weight: 600;
  line-height: 40px;
}
.checkListData_2 {
  line-height: 30px;
  .checkListData_2_1 {
    padding: 0 5px;
  }
  .checkListData_2_2 {
    padding: 0 5px;
    margin-right: 5px;
  }
}
</style>